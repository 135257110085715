import Button from 'components/Button'
import Modal from 'components/Modal'
import When from 'components/When'
import React, { useState } from 'react'
import useAuth from 'store/AuthContext'
import LoginForm from './components/LoginForm'
import RegisterForm from './components/RegisterForm'
import * as S from './LoginModal.style'
import ChangePassword from './components/ChangePassword'

const LoginModal = props => {
  const [formType, setFormType] = useState('login')
  const { loginModalShown, setLoginModalShow } = useAuth()

  let title = formType === 'login' ? 'ავტორიზაცია' : 'რეგისტრაცია'
  title = formType === 'forgot' ? 'პაროლის აღდგენა' : title

  return (
    <S.CustomLoginModal>
      <Modal
        show={loginModalShown}
        title={title}
        disableCloseTitle
        onClose={() => setLoginModalShow(false)}
      >
        <S.LoginModalWrapper>
          <When condition={formType === 'login'}>
            <LoginForm setFormType={setFormType} />
          </When>
          <When condition={formType === 'register'}>
            <RegisterForm onLogin={() => setFormType('login')} />
          </When>
          <When condition={formType === 'forgot'}>
            <ChangePassword />
          </When>
        </S.LoginModalWrapper>

        <When condition={formType === 'login'}>
          <S.RegisterButton>
            <Button color="lightBlue" onClick={() => setFormType('register')}>
              გაიარეთ რეგისტრაცია
            </Button>
          </S.RegisterButton>
        </When>
      </Modal>
    </S.CustomLoginModal>
  )
}

export default LoginModal
