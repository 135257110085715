import styled from 'styled-components'

export const CategoriesWrapper = styled.div`
  display: flex;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  flex-direction: column;
  width: fit-content;
  ${props => props.mobileTop ? 'width : 22px;' : ''}
  ${props => props.withoutPadding ?
          `li { 
    padding-left: 0;
    padding-right: 0;
  }`
          : ''}
  ${props => props.withPaddingBottom ?
          `padding-bottom: 40px`
          : ''}
  position: absolute;
  z-index: 99;

  ${props => props.innerPage ? `
    position: initial;
  ` : 'position: initial;'} @media screen and(min-width: 1100 px) {
  display: none;
  position: initial;

  &.desktop-category {
    display: flex;
    ${props => props.withoutStyles ? '' : 'box-shadow: 0 2px 3px rgba(0,0,0,0.2);'}
  }
}
`

export const BadgeNew = styled.span`
  position: absolute;
  top: -6px;
  ${props => props.isMobile ? 'left: 120px' : 'right: -29px'};
  font-size: 10px;
  color: white;
  background: red;
  padding: 1px 6px;
  border-radius: 10px;
  letter-spacing: 1px;
`

export const InnerCategoriesWrapper = styled.div`
  display: flex;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  flex-direction: column;
  width: fit-content;
  ${props => props.mobileTop ? 'width : 22px;' : ''}
  ${props => props.withoutPadding ?
          `li { 
    padding-left: 0;
    padding-right: 0;
  }`
          : ''}
  ${props => props.withPaddingBottom ?
          `padding-bottom: 40px`
          : ''}
  position: absolute;
  z-index: 99;

  ${props => props.innerPage ? `
    position: initial;
  ` : 'position: initial;'} @media screen and(min-width: 1100 px) {
  display: none;
  position: initial;

  &.desktop-category {
    display: flex;
    ${props => props.withoutStyles ? '' : 'box-shadow: 0 2px 3px rgba(0,0,0,0.2);'}
  }
}
`


export const DesktopHeaderCategoriesContainer = styled.div`
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  overflow: hidden;
`
export const DesktopHeaderCategoriesLeft = styled.div`
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  /* background-color: #ff2337; */
  background: linear-gradient(268.06deg, #FF416C 0%, #FF4B2B 100%);
  width: 13%;
  display: flex;
  justify-content: center;
  align-items: center;

`

export const DesktopHeaderCategoriesRight = styled.div`
  width: 88%;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: space-around;
  background-color: #F2F4F5;
  height: 70px;
`

export const DesktopHeaderCategoriesItem = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in;
  padding: 5px 10px;

  & img {
    width: 24px;
    height: 24px;
  }

  p {
    margin-top: 5px;
    color: #606263;
    /* font-size: 0.8rem; */
    font-size: 12px;
    white-space: nowrap;
  }
  
  &:hover{
    overflow: hidden;
    transform: scale(1.1);
    /* background-image: linear-gradient(to top, #ffffff, #f3f3f3, #e8e8e8, #dcdcdc, #d1d1d1); */
  }
`

export const CategoriesHeader = styled.div`

  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 1100px) {
    padding-left: 14px;
    padding-top: ${props => props.withoutStyles ? '0px' : '16px'};
  }

`

export const InnerCategoriesHeader = styled.div`

  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 1100px) {
    padding-left: 14px;
    padding-top: ${props => props.withoutStyles ? '0px' : '16px'};
  }

`
export const HomeCategoriesHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

`

export const CatHeaderText = styled.div`
  font-size: ${props => props.theme.font.size.sm};
  font-family: ${props => props.theme.font.family.bold};
  color: ${props => (props.homeDesktop ? 'white' : 'black')};

  @media screen and (min-width: 1100px) {

  }
`

export const CatHeaderTitle = styled(CatHeaderText)`
  font-size: 13px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  justify-content: center;

  P {
    ${props => props.homeDesktop ? 'width: 78px; line-height:1.1rem; font-size:14px; margin-left:5px' : ''}
    font-family: ${props => props.theme.font.family.medium};
    font-weight: normal;
    letter-spacing: 0.2px;
    /* font-feature-settings: "case" */;
    margin-bottom: 0;
  }

  @media screen and (min-width: 1100px) {
  }

  svg {
    transform: translateY(-1.5px);
    margin-right: 20px;
    height: 20px;
    width: 20px;
  }
`

export const InnerCatHeaderTitle = styled(CatHeaderText)`
  font-size: 13px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  justify-content: center;

  P {
    ${props => props.homeDesktop ? 'width: 78px; line-height:1.1rem; font-size:14px; margin-left:5px' : ''}
    font-family: ${props => props.theme.font.family.medium};
    font-weight: bold;
    letter-spacing: 0.2px;
    /* font-feature-settings: "case" */;
    margin-bottom: 0;
  }

  @media screen and (min-width: 1100px) {
  }

  svg {
    transform: translateY(-1.5px);
    margin-right: 20px;
    height: 20px;
    width: 20px;
  }
`


export const CategoriesLI = styled.li`
  padding: 0px 15px;
  ${props => props.mobileTop ? 'padding-left:25px; padding-top:5px; width:100vw;' :''};
  
  display: grid;
  background: #fff;
  grid-template-columns: 1fr auto;
  grid-column-gap: 20px;
  align-items: center;

  .catIcon {
    height: 16px;
    width: 18px;
  }

  @media screen and (min-width: 1100px) {
    display: flex;
    width: 305px;
    /* min-height: 50px; */
    min-height: 40px;
    &:hover {
      background: #F7F9FA;
      a {
        /* color: #ff2337; */
        font-size: unset;
      }
    }

    @media screen and (max-width: 1399px) {
      width: 250px;
    }
    @media screen and (max-width: 1249px) {
      width: 250px;
    }
  }


  ${props => props.css ? props.css : ''}
  a {
    padding: 9px 0px 7px 0px;
    display: flex;
    width: 100%;
    /* justify-content: space-between; */
    font-size: ${props => props.theme.font.size.sm};
    color: ${props => props.theme.color.black1};
    @media screen and (max-width: 1100px){
      color: ${props => props.theme.color.black};
    }
    font-family: ${props => props.theme.font.family.medium};
    align-items: center;
    text-decoration: none;

    ${props => props.css ? props.css : ''}
    ${props => props.css && props.css.indexOf('color:') > -1 ? 'border-bottom: 0' : ''}
    span {
      display: flex;
      align-items: center;
    }

    .menu-badge {
      background: #f30;
      border-radius: 4px;
      font-size: 10px;
      color: #fff;
      padding: 2px 5px;
      margin-left: 10px;
      height: 13px;
      line-height: 1;
      text-transform: uppercase;
      display: inline-block;
      align-items: center;
      font-weight: 700;
    }
  }
  .back-button{
    display: flex;
    align-items: center;
    padding-top: 10px;
    margin-bottom: 5px;
    div{
      padding-top: 2px;
      margin-left: 7px;
      font-weight: bold;
      font-size: 14px;
      font-family: ${props=> props.theme.font.family.medium};
      color: ${props=> props.theme.color.grey};
    }
  }
  .mobile-li-div{
    display: flex;
    align-items: center; 
    font-size: 14px;
    padding-top: 9px;
    padding-bottom: 7px;
    font-family: ${props=> props.theme.font.family.medium};
  }
  
  .category-mobile-img{
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
  .right-arrow-mobile{
    position: absolute;
    right: 0;
  }

`

export const InnerCategoriesLI = styled.li`
  padding: 0px 15px;
  display: grid;
  background: #fff;
  grid-template-columns: 1fr auto;
  grid-column-gap: 25px;
  align-items: center;

  ${props => props.css ? props.css : ''}
  a {
    padding: 9px 0px 7px 0px;
    display: flex;
    width: 100%;
    /* justify-content: space-between; */
    font-size: ${props => props.theme.font.size.sm};
    color: ${props => props.theme.color.black1};
    font-family: ${props => props.theme.font.family.medium};
    align-items: center;
    ${props => props.withoutBorderBottom ? '' : `
      border-bottom: 1px solid ${props.withoutStyles ? 'transparent' : props.theme.color.greyED};
    `}
    text-decoration: none;

    ${props => props.css ? props.css : ''}
    ${props => props.css && props.css.indexOf('color:') > -1 ? 'border-bottom: 0' : ''}
    span {
      display: flex;
      align-items: center;
    }

    .menu-badge {
      background: #f30;
      border-radius: 4px;
      font-size: 10px;
      color: #fff;
      padding: 2px 5px;
      margin-left: 10px;
      height: 13px;
      line-height: 1;
      text-transform: uppercase;
      display: inline-block;
      align-items: center;
      font-weight: 700;
    }
  }

  @media screen and (min-width: 1100px) {

  }
`

export const CategoryBackTitle = styled.li`
  padding: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding-bottom: 16px;

  a {
    display: inline-flex;
    gap: 8px;
    width: 100%;
    justify-content: flex-start;
    font-size: ${props => props.theme.font.size.sm};
    color: ${props => props.theme.color.black1};
    font-family: ${props => props.theme.font.family.medium};
    align-items: center;
    ${props => props.withoutBorderBottom ? '' : `
      border-bottom: 1px solid ${props.withoutStyles ? 'transparent' : props.theme.color.greyED};
    `}
    text-decoration: none;

    span {
      display: flex;
      align-items: center;
    }
  }

  @media screen and (min-width: 1100px) {
    color: black !important;
  }
`

export const CategoriesUL = styled.ul`
  ${props => props.mobileTop ? 'top: 106px; left:0; position:absolute; width: 100vw;' : ''};
  ${props => props.productPage ? 'top: 70px; position:absolute; left:0;': props.homeDesktop ?'top: 150px; position:absolute; left:0;' :""}
  z-index: 2000;
  list-style: none;
  width: calc(100% - 20px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  ${props => props.open ? 'max-height: 700px;opacity: 1;visibility: visible;' : 'max-height: 0;opacity: 0;visibility: hidden;'} /* transition: .3s; */ margin-bottom: 16px;
  
  li${CategoriesLI} {
    &::last-child {
      a {
        border: none;
      }
    }

    &.active {
      .sub-menu {
        max-height: unset;
        opacity: 100%;
        visibility: visible;
      }
    }
  }

  @media screen and (min-width: 1100px) {
    max-height: unset;
    width: unset;
    margin-bottom: 0px;
    box-shadow: unset;
    li${CategoriesLI} {
      &:hover {
        .sub-menu {
          width: 60vw;
          opacity: 1;
          visibility: visible;
          /* transition: .3s; */
        }

      }

    }
  }
`
export const InnerCategoriesUL = styled.ul`
  ${props => props.mobileTop ? 'top: 65px; position:absolute' : ''};
  ${props => props.homeDesktop ? 'top: 158px; position:absolute ' : ''};
  z-index: 99;
  list-style: none;
  width: calc(100% - 20px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  ${props => props.open ? 'max-height: 700px;opacity: 1;visibility: visible;' : 'max-height: 0;opacity: 0;visibility: hidden;'} /* transition: .3s; */ margin-bottom: 16px;

  li${CategoriesLI} {
    &::last-child {
      a {
        border: none;
      }
    }

    &.active {
      .sub-menu {
        max-height: unset;
        opacity: 100%;
        visibility: visible;
      }
    }

  }

  @media screen and (min-width: 1100px) {
    max-height: unset;
    width: unset;
    margin-bottom: 0px;
    box-shadow: unset;
    li${CategoriesLI} {
      &:hover {
        .sub-menu {
          width: 60vw;
          opacity: 1;
          visibility: visible;
          /* transition: .3s; */
        }

      }

    }
  }
`

export const SubCategoryContainer = styled.div`

  width: 100%;
  height: auto;
  background-color: white;
  overflow: hidden;
  max-height: 0px;
  visibility: hidden;
  opacity: 0;
  /* transition: .3s; */

  @media screen and (min-width: 1100px) {
    /* transition: unset; */
    position: absolute;
    height: auto;
    top: 0px;
    z-index: 999;
    left: 100%;
    box-sizing: border-box;
    max-height: unset;
    overflow: visible;
    width: 0;
  }


`
export const InnerSubCategoryContainer = styled.div`

  width: 100%;
  height: auto;
  background-color: white;
  overflow: hidden;
  max-height: 0px;
  visibility: hidden;
  opacity: 0;
  /* transition: .3s; */

  @media screen and (min-width: 1100px) {
    border-top: 2px solid ${props =>
            props.withoutStyles ? 'transparent' : props.theme.color.blue1};
    /* transition: unset; */
    position: absolute;
    height: auto;
    top: 0px;
    z-index: 999;
    left: 100%;
    box-sizing: border-box;
    max-height: unset;
    overflow: visible;
    width: 0;
  }


`

export const SubCategoryLists = styled.div`
  padding-left: 20px;
  position: relative;
  background: #fff;
  @media screen and (min-width: 1100px) {
    background: #F7F9FA;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    padding: 0px 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    //grid-auto-flow: row dense;
    width: 1085px;
    /* min-height: 648px; */
    min-height: 500px;
    
    img {
      position: absolute;
      z-index: 90;
      bottom: 0;
      right: 0;
      height: 320px;
      width: 320px;
    }

    @media screen and (max-width: 1399px) {
      width: 1000px;
      img {
        height: 300px;
        width: 300px;
      }
    }
    @media screen and (max-width: 1249px) {
      width: 850px;
      img {
        height: 280px;
        width: 280px;
      }
    }
  }
`

export const InnerSubCategoryLists = styled.div`
  padding-left: 20px;
  background: #fff;
  @media screen and (min-width: 1100px) {
    background: #f6f6f6;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    padding: 0px 50px;
    padding-top: 25px;
    padding-bottom: 32px;
    grid-auto-flow: row dense;
  }
`

export const SubCategoryList = styled.div`
  h3:not(.no-children) {
    font-size: ${props => props.theme.font.size.sm};
    font-family: ${props => props.theme.font.family.medium};
    color: black;

    a {
      border: none;
      font-size: 11pt;
      /* height: 40pt; */
      height: 40px;
      margin-bottom: 5px;
      color: black;
    }
  }

  h3.no-children {
    font-size: ${props => props.theme.font.size.sm};
    font-family: ${props => props.theme.font.family.medium};
    color: black;

    a {
      border: none;
      font-size: 11pt;
      /* height: 40pt; */
      height: 40px;
      margin-bottom: 5px;
      color: black;
    }
  }

  ul {
    list-style: none;

    li {
      a {
        border: none;
        padding: 5px 0px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  h3 {
    a {
      @media screen and (min-width: 1100px) {
        font-family: ${props=> props.theme.font.family.medium} !important;
        font-weight: bold !important;
        @media screen and (max-width: 1399px) {
          
        }
        @media screen and (max-width: 1249px) {
          font-size: 13px !important;
        }
      }
    }
  }

  .single-cat {
    @media screen and (min-width: 1100px) {
      color: #4D4D4D !important;
      font-family: ${props=> props.theme.font.family.medium} !important;
      font-weight: normal !important;
      font-size: 12px;
      margin-top: 2px;
      @media screen and (max-width: 1399px) {
      }
      @media screen and (max-width: 1249px) {
        font-size: 13px !important;
      }
    }
  }
`
export const InnerSubCategoryList = styled.div`
  h3:not(.no-children) {
    font-size: ${props => props.theme.font.size.sm};
    font-family: ${props => props.theme.font.family.medium};
    color: black;

    a {
      border: none;
      font-size: 11pt;
      border-bottom: 1px solid grey;
      height: 40pt;
      margin-bottom: 10px;
      color: black;
    }
  }

  h3.no-children {
    font-size: ${props => props.theme.font.size.sm};
    font-family: ${props => props.theme.font.family.medium};
    color: black;

    a {
      border: none;
      font-size: 11pt;
      height: 40pt;
      margin-bottom: 10px;
      color: black;
    }
  }

  ul {
    list-style: none;

    li {
      a {
        border: none;
        padding: 5px 0px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @media screen and (min-width: 1100px) {

  }

`

export const SubCategoryFooter = styled.div`
  display: none;
  @media screen and (min-width: 1100px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    padding: 25px 50px;

    a {
      decoration: none;
      display: grid;
      padding: 5px 0px;

      span {
        font-size: 14px;
        color: #aaa;

        &.title {
          font-size: 16px;
          color: ${props => props.theme.color.black1};
        }
      }

      &:hover {
        decoration: underline;
      }
    }

    img {
      position: absolute;
      bottom: 0px;
      rtght: 30px;
    }
  }

`
