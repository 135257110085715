import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0px;
    padding: 0px;
    font-family: ${props => props.theme.font.family.regular};
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 8px;
      height: 3px;
    }
    &::-webkit-scrollbar-track {
      width: 8px;
      height: 3px;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: ${props => props.theme.color.red2};
    }
  }

  /** For product page */
  #content-container.affiliate-active >[itemscope]:first-child>:first-child {
    margin-top: 130px !important;
  }

  /** For other page */
  #content-container.affiliate-active >:first-child:not([itemscope]) {
    margin-top: 50px !important;
  }
  
  /** For account page */
  #content-container.affiliate-active >:first-child.account-page,
  #content-container.affiliate-active >:first-child.blog-page
   {
    margin-top: 90px !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .app-main-container {
    display: grid;
    grid-template-areas: 'header header'
                         'content content'
                         'footer footer';
  }

  #crisp-chatbox {
    z-index: 33;
  }

  body {
    overflow-x: hidden;
    * {
      /* border: 1px solid red; */
    }
  }

  html {
    scroll-behavior: smooth;
  }

  .format-html {
    h1, h2, h3, h4, h5, h6 {
      display: block;
      font-weight: bold;	
    }
    h1 {
      font-size: 2em;
      margin-top: 0.67em;
      margin-bottom: 0.67em;
    }
    h2	{
      font-size: 1.5em;
      margin-top: 0.83em;
      margin-bottom: 0.83em;
    }
    h3	{
      font-size: 1.17em;
      margin-top: 1em;
      margin-bottom: 1em;
    }
    h4	{ 
      margin-top: 1.33em;
      margin-bottom: 1.33em;
    }
    h5	{
      font-size: .83em;
      margin-top: 1.67em;
      margin-bottom: 1.67em;
    }
    h6	{
      font-size: .67em;
      margin-top: 2.33em;
      margin-bottom: 2.33em;
    }
    p {
      display: block;
      margin-top: 1em;
      margin-bottom: 1em;
      margin-left: 0;
      margin-right: 0;
    }
    a {
      color: blue;
      text-decoration: underline;
    }
    ul, li {
      list-style: inside !important;
      list-style-type: inside !important;
    }
  }
`
