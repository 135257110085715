import api from './api'
import { appendTrackingParamsToFormDataObject } from 'consts'
class CartService {
  static getCart(data = {}) {
    return api
      .call('get', `/cart/show`, {
        params: appendTrackingParamsToFormDataObject(data),
      })
      .then(api.getData)
  }

  static addToCart(sku, variant) {
    return api.call('post', `/cart/add`, { sku: sku, variant: variant })
  }

  static updateQuantity(sku, quantity) {
    return api.call('post', `/cart/update`, { sku: sku, quantity: quantity })
  }

  static deleteProduct(sku) {
    return api.call('post', `/cart/delete`, { sku: sku })
  }

  static changeBank(bank) {
    return api.call('post', `/cart/change-bank`, { bank: bank })
  }

  static applySaleCode(code) {
    return api.call('post', `/cart/apply-coupon`, {
      code: code,
    })
  }

  static removeCouponCode(code) {
    return api.call('post', `/cart/remove-coupon`, {
      code: code,
    })
  }

  static changePayMethod(pay_method) {
    return api.call('post', `/cart/change-pay-method`, {
      pay_method: pay_method,
    })
  }

  static placeOrder() {
    return api.call('post', `/cart/place-order`, {})
  }

  static assignClid(data) {
    return api.call('post', `/cart/assign-clid`, data)
  }
}

export default CartService
