import styled from 'styled-components'
import Input from 'components/Input'

export const CustomLoginModal = styled.div`
    .modal-container {
        .modal-content {
            max-width: 452px;
        }

        .modal-header {
            width: 100%;
            z-index: 12;
            border: none;
            top: 3px;
        }

        .modal-body {
            padding: 27px 20px;
            padding-top: 0px;
        }

        .modal-title {
          width: 100%; 
          text-align: center;
          padding-bottom: 32px;
        }
    }

`

export const LoginModalWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 10px;
    padding: 10px;
    background-color: white;
    @media screen and (min-width: 1100px) {
        min-width: 350px;
    }
`

export const Overlay = styled.div`
    width: 50%;
    height: calc(100% - 65px);
    background-color: rgba(0,0,0,0.3);
    position: absolute;
    right: 0;
    top: 65px;
    opacity: 1;
    transition: .3s;
    z-index: 10;
    &:hover {
        opacity: 0;
        z-index: -1;
        transition: .3s;
    }
`

export const LoginModalForm = styled.form`
    display: grid;
    grid-row-gap: 15px;
    /* grid-template-rows: repeat(5, auto) 1fr; */
    position: relative;
    z-index: 11;
    background: white;
    h2 {
        margin: 0px;
        color: #394455;
        font-size: 20px;
        font-family: ${props => props.theme.font.family.bold};
    }
    h3 {
        font-size: 10px;
        color: rgba(57,68,85, 0.7);
        margin-bottom: 0px;
    }

    .orange-button {
        height: 45px;
        &.log-in {
            margin-top: 9px;
        }
    }

    .error {
      font-size: 10px;
      margin-top: -5px;
      color: ${props => props.theme.color.orange};
    }
`

export const LabelWithInput = styled.div`
    display: grid;
    grid-row-gap: 10px;
    p {
        font-size: 14px;
        color: #394455;
        font-weight: bold;
    }
    a {
      color: ${props => props.theme.color.orange}
    }
`

export const LoginInput = styled(Input)`
    border-color: #ddd;
`
export const CustomLoginInput = styled('input')`
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;

    ::placeholder{
        font-size: 14px;
    }
`

export const LoginModalSocial = styled.div`
    display: grid;
    grid-row-gap: 7px;
    margin-top: 16px;
    column-gap: 8px;
    grid-template-columns: 1fr 1fr;
    position: relative;
`

export const RegisterButton = styled.div`
    display: grid;
    grid-row-gap: 7px;
    margin-top: 46px;
    position: relative;
    &::before {
        content: 'ან';
        position: absolute;
        left: 0;
        top: -36px;
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: #394455;
    }
`

export const RegisterInfo = styled.div`
    display: grid;
    grid-row-gap: 15px;
    margin-top: 46px;
    grid-template-rows: auto 1fr;
    p {
        margin: 0px;
        font-size: 14px;
        color: #394455
    }

    ul {
        list-style: none;
        li {
            font-size: 12px;
            color: #747C88;
            display: flex;
            align-items: center;
            svg {
                margin-right: 17.6px;
            }
            &:not(:first-child) {
                margin-top: 9px;
            }
        }
    }

`
