import Message from 'components/UI/Message'
import React from 'react'
import { Fragment } from 'react'
import useAuth from 'store/AuthContext'
import { Link } from 'react-router-dom'

export default () => {
  const { auth, user } = useAuth()

  if (!auth) return null

  return (
    <Message
      show={auth && user.password_changed === 0 && false}
      type="warning"
      message={
        <Fragment>
          უსაფრთხოების მიზნით სავალდებულოა{' '}
          <Link to="/account/details">პაროლის შეცვლა</Link>
        </Fragment>
      }
    />
  )
}
