import styled from 'styled-components'

export const ModalOverlay = styled.div`
  display: ${props => props.display};
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.3);
  z-index: ${props => props.zindex};
  @media screen and (min-width: 1100px) {

  }
`

export const ModalCountainer = styled.div`
  display: ${props => props.display};
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 500;
  @media screen and (min-width: 1100px) {

  }
`

export const ModalContent = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  background-color: white;
  border: 1px solid #707070;
  border-radius: 4px;
  position: fixed;
  z-index: ${props => props.zindex};
  width: calc(100% - 10px);
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  border: none;
  box-shadow: 7px 8px 13px gray;
  padding: 4px;

  &.height-90 {
    height: 90%;

    @media screen and (min-width: 1100px) {
      height: ${props => props.modal === 'cart' ? '90%' : 'auto'};
    }
  }

  @media screen and (min-width: 1100px) {
    width: unset;
    max-width: 755px;
    top: 50%;
    left: 50%;
    max-height: 90%;
    transform: translate(-50%, -50%);
    ${props => props.modal === 'cart' ? `
      width: 700px;
      height: 90%;
    ` : ''}
    
    top: 50%;
    left: 50%;
  }
`

export const ModalHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 15px;
  padding: 8px;
  border-bottom: 1px solid #DFE3E9;

  h2 {
    font-size: 16px;
    font-family: ${props => props.theme.font.family.bold};
    color: #1A2738;
    margin: 0px;
  }

  button {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin: 0px !important;
    svg {
      margin-left: 10px;
    }
  }
  @media screen and (min-width: 1100px) {

  }


`

export const ModalBodyContainer = styled.div`
  padding: 20px;

  &.withScrollbar {
    overflow-y: auto;
    height: 100%;
  }

  @media screen and (min-width: 1100px) {
  }
`

export const ModalFooterContainer = styled.div`
  padding: 24px 26px;
  /* display: grid;
  grid-template-columns: 1fr auto auto; */
  grid-column-gap: 10px;
  align-items: center;


  .cart-buy-buttons {
    gap: 20px;
  }

  @media screen and (min-width: 1100px) {

  }
`
