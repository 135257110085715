import translate from 'helpers/translate'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import useAuth from 'store/AuthContext'
import useConfig from 'store/ConfigProvider'
import {
  CopyrightText,
  FooterContact,
  FooterContainer,
  FooterGeneralInfo,
  FooterLogo,
  FooterMenu,
  FooterPhone,
  FooterTop,
} from './Footer.style'

const Footer = props => {
  const { settings, footer, isLoaded } = useConfig()
  const { setLoginModalShow, auth } = useAuth()

  const handleMyProfileClick = e => {
    if (!auth) {
      e.preventDefault()
      setLoginModalShow(true)
    }
  }
  const handleMyOrdersClick = e => {
    if (!auth) {
      e.preventDefault()
      setLoginModalShow(true)
    }
  }

  if (!isLoaded) return null

  return (
    <FooterContainer>
      <FooterTop>
        <FooterGeneralInfo>
          <FooterLogo to="/">
            <img alt="Citrus" src="/img/Citrus.png" />
          </FooterLogo>
          {/* <FooterPhone>
            <p>{translate('contactUs')}:</p>
            <h4>{settings.phone_number}</h4>
          </FooterPhone> */}
          <FooterContact>
            <strong>{translate('workingHours')}:</strong>
            <p>
              ორშ-პარ: 10:00-19:00
              <br />
              შაბ: 10:00-15:00
              <br />
            </p>
            <strong>{translate('address')}:</strong>
            <address>
              პეკინის გამზირი 34/ ალ.ყაზბეგის გამზ. 2ა, 0160
              <br />
              თბილისი, საქართველო
            </address>
          </FooterContact>
        </FooterGeneralInfo>

        <div className="flex flex-col md:flex-row">
          {(footer || []).map((footer, footer_key) => {
            if (!Array.isArray(footer.children) && !footer.children.count)
              return null
            return (
              <FooterMenu key={footer.id}>
                <h4>{footer.title}</h4>
                <ul>
                  <ul>
                    {footer_key === 2 ? (
                      <Fragment>
                        <li>
                          <Link
                            to="/account/orders"
                            onClick={handleMyProfileClick}
                          >
                            ჩემი პროფილი
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={handleMyOrdersClick}
                            to="/account/orders"
                          >
                            ჩემი შეკვეთები
                          </Link>
                        </li>
                      </Fragment>
                    ) : null}
                    {footer.children.map((child, key) => {
                      return (
                        <li key={child.id}>
                          <Link to={child.url || ''}>{child.title || ''}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </ul>
              </FooterMenu>
            )
          })}
        </div>
      </FooterTop>
      <div className="footer flex flex-col md:flex-row pt-4 justify-between gap-y-4 gap-x-4 md:pr-10">
        <div className="footer-payment justify-center flex flex-row gap-x-4">
          <img alt="tbc" src="/img/tbc.jpg" />
          <img alt="credo" src="/img/credo.jpg" />
          <a
            href="//www.dmca.com/Protection/Status.aspx?ID=b90b506b-1a6c-40ac-9116-683e00eb0942"
            title="DMCA.com Protection Status"
            className="dmca-badge"
          >
            <img
              src="https://images.dmca.com/Badges/dmca_protected_sml_120s.png?ID=b90b506b-1a6c-40ac-9116-683e00eb0942"
              alt="DMCA.com Protection Status"
            />
          </a>
          <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>
        </div>
        <div className="footer-social justify-center flex flex-row gap-x-4">
          {settings.facebook_url && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={settings.facebook_url}
            >
              <img alt="facebook" src="/img/facebook.svg" />
            </a>
          )}
          {settings.youtube_url && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={settings.youtube_url}
            >
              <img alt="youtube" src="/img/youtube.svg" />
            </a>
          )}
          {settings.instagram_url && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={settings.instagram_url}
            >
              <img alt="instagram" src="/img/instagram.svg" />
            </a>
          )}
        </div>
      </div>
      <CopyrightText>
        <p>{translate('footerText')}</p>
      </CopyrightText>
    </FooterContainer>
  )
}

export default Footer
