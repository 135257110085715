import React, { useState } from 'react'
import PasswordChangeAlert from 'components/Alerts/PasswordChangeAlert'
import * as S from 'components/Widgets/LoginModal/LoginModal.style'
import Button from 'components/Button'
import UserService from 'services/UserService'
import useAuth from 'store/AuthContext'
import ShowPassword from 'components/Icons/Eye/ShowPassword'
import HidePassword from 'components/Icons/Eye/HidePassword'
import { toast } from 'react-toastify'

export default function ChangePassword({ setFormType }) {
  const { userAuthenticated } = useAuth()
  const [loading, setLoading] = useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [repeatPasswordVisible, setRepeatPasswordVisible] = useState(false)

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const toggleRepeatPasswordVisibility = () => {
    setRepeatPasswordVisible(!repeatPasswordVisible)
  }

  const forgotPasswordSubmit = e => {
    e.preventDefault()
    setLoading(true)

    const formData = new FormData(e.target)

    UserService.forgotPassword(formData)
      .then(data => {
        setLoading(false)
        userAuthenticated()
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          // Extract the first error message
          const errorMessages = Object.values(err.response.data.errors).flat()
          toast.error(errorMessages[0], {
            theme: 'colored',
          })
        } else if (err.response.status === 429) {
          toast.error(
            err.response.data.message ||
              'Too Many Requests. Please try again later.',
            { theme: 'colored' }
          )
        } else {
          toast.error('An unexpected error occurred', {
            theme: 'colored',
          })
        }
      })
  }
  return (
    <form
      id="forgot-password"
      className="form-section"
      onSubmit={forgotPasswordSubmit}
    >
      <PasswordChangeAlert />
      <div className="flex flex-col">
        <div className="w-full mb-4">
          <S.LabelWithInput>
            <p>SMS კოდი *</p>
            <S.CustomLoginInput
              type="text"
              name="code"
              autoComplete="one-time-code"
              placeholder="შეიყვანე SMS კოდი"
            />
          </S.LabelWithInput>
        </div>
        <div className="w-full mb-4">
          <S.LabelWithInput>
            <p>ახალი პაროლი *</p>
            <div style={{ position: 'relative' }}>
              <S.CustomLoginInput
                type={passwordVisible ? 'text' : 'password'}
                name="password"
                autoComplete="new-password"
                id="password"
                placeholder="შეიყვანეთ ახალი პაროლი"
                style={{ width: '100%' }}
              />
              <span
                onClick={togglePasswordVisibility}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                  zIndex: 111,
                }}
              >
                {passwordVisible ? <HidePassword /> : <ShowPassword />}
              </span>
            </div>
          </S.LabelWithInput>
        </div>

        <div className="w-full">
          <S.LabelWithInput>
            <p>დაადასტურეთ პაროლი *</p>
            <div style={{ position: 'relative' }}>
              <S.LoginInput
                type={repeatPasswordVisible ? 'text' : 'password'}
                name="repeat_password"
                placeholder="გაიმეორე პაროლი"
                style={{ width: '100%' }}
              />
              <span
                onClick={toggleRepeatPasswordVisibility}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                  zIndex: 111,
                }}
              >
                {repeatPasswordVisible ? <HidePassword /> : <ShowPassword />}
              </span>
            </div>
          </S.LabelWithInput>
        </div>
        <Button
          type="submit"
          color="orange"
          className="mt-4"
          onSubmit={forgotPasswordSubmit}
          disabled={loading}
        >
          პაროლის აღდგენა
        </Button>
      </div>
    </form>
  )
}
