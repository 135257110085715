export const tokenKey = 'citrustoken'
export const signatureKey = 'citrussignature'

export function canCredoCashback(productPrice) {
  return false
}

export function calculateCredoCashback(productPrice) {
  return Math.round(Math.max(productPrice * 0.7, productPrice - 250))
}

export function calculateCredoCashbackAmount(productPrice) {
  return Math.round(Math.min(productPrice * 0.3, 250))
}

/** Citrus cashbacks */
export function canCitrusCashback(productPrice, payMethod) {
  return false
  // return productPrice > 0 && payMethod === 'card'
}

export function calculateCitrusCashback(productPrice) {
  return Math.round(Math.max(productPrice * 0.85, productPrice - 100))
}

export function calculateCitrusCashbackAmount(productPrice) {
  return Math.round(Math.min(productPrice * 0.15, 100))
}

export function canTbcConceptCashback(productPrice, payMethod, bank) {
  const cutoffTime = new Date('2025-03-15T23:59:00+04:00')
  const currentTime = new Date()

  return (
    productPrice > 0 &&
    payMethod === 'card' &&
    bank === 'tbc' &&
    currentTime < cutoffTime
  )
}

export function calculateTbcConceptCashback(productPrice) {
  return Math.min(Math.floor(productPrice * 0.3), 100)
}

export function calculateTbcConceptCashbackAmount(productPrice) {
  const cashback = Math.min(Math.floor(productPrice * 0.3), 100)
  return productPrice - cashback
}

export const getUrlParam = param => {
  var url = new URL(window.location.href)
  var c = url.searchParams.get(param)
  return c
}

export const appendTrackingParamsToFormData = formData => {
  formData.append('ctclid', localStorage.getItem('ctclid'))
  formData.append('gclid', localStorage.getItem('gclid'))
  formData.append('fbclid', localStorage.getItem('fbclid'))
  return formData
}

export const appendTrackingParamsToFormDataObject = object => {
  object.ctclid = localStorage.getItem('ctclid')
  object.gclid = localStorage.getItem('gclid')
  object.fbclid = localStorage.getItem('fbclid')
  return object
}
export function isInSale(old_price, new_price) {
  return old_price <= 0 ||
    old_price === null ||
    new_price <= 0 ||
    new_price === null
    ? false
    : true
}

export function percentageSaleCalculator(old_price, new_price) {
  if (old_price <= 0 || old_price < new_price || new_price <= 0) return
  let sale = ((old_price - new_price) / old_price) * 100
  return sale.toFixed(0)
}
