import api from './api'

class UserService {
  static login(data) {
    return api
      .call('post', `/auth/login`, data)
      .then(api.getData)
      .then(data => {
        if (data.token) {
          api.setToken(data.token)
        }
        return data
      })
  }

  static register(data) {
    return api
      .call('post', `/auth/register`, data)
      .then(api.getData)
      .then(data => {
        if (data.token) {
          api.setToken(data.token)
        }
        return data
      })
  }

  static forgotPassword(data) {
    return api
      .call('post', `/forgot-password`, data)
      .then(api.getData)
      .then(data => {
        if (data.token) {
          api.setToken(data.token)
        }
        return data
      })
  }

  static bootstrap(data) {
    return api.call('post', `/auth/bootstrap`, data).then(api.getData)
  }

  static edit(data) {
    return api.call('post', `/auth/edit`, data).then(api.getData)
  }

  static sendRestoreCode() {
    return api.call('post', `/auth/send-restore-code`).then(api.getData)
  }

  static sendNewPassword(formData) {
    return api
      .call('post', `/auth/send-new-password`, formData)
      .then(api.getData)
  }

  static logOut() {
    api.logOut()

    // return api
    //   .call('post', `/auth/logout`)
    //   .then(api.getData)
    //   .then(data => {
    //     return data
    //   })
    //   .catch(() => {})
    //   .then(() => {
    //     api.logOut()
    //   })
  }

  static sendConfirmationCode(phone) {
    return api
      .call('post', `/auth/send-confirmation`, { phone: phone })
      .then(api.getData)
  }
}

export default UserService
